import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

import { images } from 'utils/images';
import { primary } from 'styles/colors';
import { mobile, useQuery } from 'styles/breakpoints';
import {
  Container,
  FlexWrapper,
  H2,
  PrimaryButton,
  Svg,
  TextColor,
  TextWrapper,
} from 'components';

import { OnlineTreatmentItem } from '../elements/OnlineTreatmentItem';

interface Props {
  onClick?: () => void;
  ctaTitle?: string;
}

const GetTreatmentOnline: React.FC<Props> = React.memo(
  ({ onClick, ctaTitle }) => {
    const { isMobile } = useQuery();

    return (
      <StyledContainer>
        <Svg
          src="icons/leaves_2"
          width="52"
          height="36"
          viewBox="0 0 52 36"
          fill="none"
          style={{ display: 'block', margin: '0 auto' }}
        />
        <TextWrapper
          maxWidth="37.125rem"
          margin={isMobile ? '0.5rem auto 3.125rem' : '0.5rem auto 5rem'}
        >
          <H2 textAlign="center">
            Don&apos;t wait until it&apos;s too late. Save time and{' '}
            <TextColor color={primary}>start prevention now.</TextColor>
          </H2>
        </TextWrapper>
        <FlexWrapper
          alignItems="center"
          justifyContent="space-between"
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Parallax
            disabled={isMobile}
            y={isMobile ? [0, 0] : [0, 0]}
            styleOuter={isMobile ? { marginBottom: '1.25rem' } : null}
          >
            <OnlineTreatmentItem
              step="1"
              title="Take the consultation quiz"
              text="Answer essential questions about your symptoms and medical history so our physicians could recommend a treatment plan."
              bgImage={images.online_treatment_1}
            />
          </Parallax>
          <Parallax
            disabled={isMobile}
            y={isMobile ? [0, 0] : [20, -10]}
            styleOuter={isMobile ? { marginBottom: '1.25rem' } : null}
          >
            <OnlineTreatmentItem
              step="2"
              title="Skip lines"
              text="Don't spend your time in pharmacies. We'll deliver your products straight to your doorstep whenever it's comfortable for you."
              bgImage={images.online_treatment_2}
            />
          </Parallax>
          <Parallax disabled={isMobile} y={isMobile ? [0, 0] : [40, -20]}>
            <OnlineTreatmentItem
              step="3"
              title="Get long-term relief"
              text="We’ll check up on you every once in a while, and based on your progress, gradually adjust your treatment plan."
              bgImage={images.online_treatment_3}
            />
          </Parallax>
        </FlexWrapper>
      </StyledContainer>
    );
  },
);

GetTreatmentOnline.displayName = 'GetTreatmentOnline';

export default GetTreatmentOnline;

const StyledContainer = styled(Container)`
  padding-top: 6.125rem;
  padding-bottom: 9.375rem;

  @media ${mobile} {
    padding-top: 4.625rem;
    padding-bottom: 3.75rem;
  }
`;
