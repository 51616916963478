import React from 'react';
import styled from 'styled-components';

import { mobile } from 'styles/breakpoints';
import { almostWhite, primary, text70 } from 'styles/colors';
import { Caption, Circle, Regular, TextWrapper } from 'components';

interface Props {
  bgImage: string;
  step: string;
  title: string;
  text: string;
}

export const OnlineTreatmentItem: React.FC<Props> = React.memo(
  ({ step, title, text, bgImage }) => (
    <Container bgImage={bgImage}>
      <StyledCircle size={2.375} color={primary}>
        <Caption color={almostWhite}>{step}</Caption>
      </StyledCircle>
      <TextWrapper padding="11.5rem 0 0">
        <Caption margin="0 0 0.5rem">{title}</Caption>
        <Regular color={text70}>{text}</Regular>
      </TextWrapper>
    </Container>
  ),
);

OnlineTreatmentItem.displayName = 'OnlineTreatmentItem';

const Container = styled.div<{ bgImage: string }>`
  position: relative;
  max-width: 24.125rem;
  width: 100%;
  padding: 0 2.625rem 2.625rem;
  border-radius: 0.625rem;
  background-color: ${almostWhite};
  background-image: url(${props => props.bgImage});
  background-position: top right;
  background-repeat: no-repeat;
  min-height: 385px;
  border: 1px solid rgba(14, 79, 106, 0.1);

  @media ${mobile} {
    margin-bottom: 1.25rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
const StyledCircle = styled(Circle)`
  position: absolute !important;
  top: 3rem;
  left: 2rem;
  @media ${mobile} {
    top: 15%;
  }
`;
